/* eslint-disable no-shadow */

export enum CommonPrefixes {
  StatusRegistrations = 'StatusRegistrations',
  Countries = 'Countries',
  States = 'States',
  NationalityStates = 'NationalityStates',
  Cities = 'Cities',
  NationalityCities = 'Nationality',
  Nationalities = 'Nationalities',
  Occupations = 'Occupations',
  MaritalStatus = 'MaritalStatus',
  DocumentTypes = 'DocumentTypes',
  DocumentIssuerAgencies = 'DocumentIssuerAgencies',
  Genders = 'Genders',
  Receipt = 'Receipt',
  Banks = 'Banks',
  Advisors = 'Advisors',
  RepresentativeCategory = 'RepresentativeCategory',
  Constitutions = 'Constitutions',
  BusinessActivities = 'BusinessActivities',
  Fatca = 'Fatca',
  Mtm = 'Mtm',
}
