import { loop, Cmd, Loop, LoopReducer } from 'redux-loop';

import { MarketToMarketStates, MarketToMarketAction } from './types';
import {
  MTM_GET_INIT,
  MTM_GET_ERROR,
  MTM_GET_SUCCESS,
  MTM_CHANGE_FLAG,
} from './constants';

import {
  fetchMarketToMarketSuccess,
  fetchMarketToMarketError,
  fetchMarketToMarket,
} from './actions';

const initialState: MarketToMarketStates = {
  mtmSuccess: false,
  mtmError: undefined,
  mtmLoading: false,
  mtmStatus: null,
  mtmFlag: true,
};

export const marketToMarkerReducer: LoopReducer<
  MarketToMarketStates,
  MarketToMarketAction
> = (
  state = initialState,
  action: MarketToMarketAction
): Loop<MarketToMarketStates> | MarketToMarketStates => {
  switch (action.type) {
    case MTM_GET_INIT:
      return loop(
        {
          ...state,
          mtmLoading: true,
        },
        Cmd.run(fetchMarketToMarket, {
          successActionCreator: fetchMarketToMarketSuccess,
          failActionCreator: fetchMarketToMarketError,
        })
      );
    case MTM_GET_ERROR:
      return {
        ...state,
        mtmLoading: false,
        mtmError: action.error,
        mtmSuccess: false,
      };

    case MTM_GET_SUCCESS:
      return {
        ...state,
        mtmStatus: action.MtmActivated,
        mtmLoading: false,
        mtmError: undefined,
        mtmSuccess: false,
      };
    case MTM_CHANGE_FLAG:
      return { ...state, mtmFlag: action.value };
    default:
      return state;
  }
};
