import {
  MarketToMarketAction,
  GetMtm,
  putMtmPayload,
  ChangeMtmFlagTypes,
} from './types';
import {
  MTM_GET_INIT,
  MTM_GET_SUCCESS,
  MTM_GET_ERROR,
  MTM_CHANGE_FLAG,
} from './constants';

import { normalizeGetMarketToMarket } from './normalizer';

import Api from '../../Helpers/Api';

export const fetchMarketToMarketInit = (): MarketToMarketAction => ({
  type: MTM_GET_INIT,
});

export const fetchMarketToMarketSuccess = (
  mtm: GetMtm
): MarketToMarketAction => ({
  type: MTM_GET_SUCCESS,
  MtmActivated: mtm.MtmActivated,
});

export const fetchMarketToMarketError = (
  error: Error
): MarketToMarketAction => ({
  type: MTM_GET_ERROR,
  error,
});

export const fetchMarketToMarket = (): Promise<GetMtm> => {
  return Api<GetMtm>({
    method: 'GET',
    service: 'terra.fixedincome',
    url: `api/position/client/mtm`,
    authenticate: true,
    returnResponse: true,
  }).then(normalizeGetMarketToMarket);
};

export const putMarketToMarket = (status: putMtmPayload): Promise<GetMtm> =>
  Api<GetMtm>({
    method: 'PUT',
    service: 'terra.fixedincome',
    url: `api/position/client/mtm`,
    authenticate: true,
    body: {
      ...status,
    },
  }).then(normalizeGetMarketToMarket);

export const changeMtmFlag = (value: boolean): ChangeMtmFlagTypes => ({
  type: MTM_CHANGE_FLAG,
  value,
});
