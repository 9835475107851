export const normalizeBanks = (banks) =>
  banks
    .map((bank) => ({
      id: bank.Value,
      value: `${bank.Value} - ${bank.Description}`,
    }))
    .sort((a, b) => (a.value < b.value ? -1 : 1));

export const normalizeAdvisors = (advisors) =>
  advisors
    .map((advisor) => ({
      id: advisor.Id,
      value: advisor.Name,
      ...advisor,
    }))
    .sort((a, b) => (a.value < b.value ? -1 : 1));

export const normalizeRepresentativeCategories = (options) =>
  options.map((option) => ({ id: option.Id, value: option.Name, ...option }));

const sendOptionToEndOfArray = (field: any, fieldValue: any, array: [any]) => {
  const optionFinded = array.find((item) => item[field] === fieldValue);
  const item = array.splice(array.indexOf(optionFinded), 1)[0];
  if (item) array.push(item);
};

export const normalizeConstitutions = (constitutions) => {
  const normalizedConstitutions = constitutions
    .map((activity) => ({
      id: activity.Id,
      value: activity.Description,
      ...activity,
    }))
    .sort((a, b) => (a.value < b.value ? -1 : 1));
  sendOptionToEndOfArray('value', 'Outros', normalizedConstitutions);
  return normalizedConstitutions;
};

export const normalizeBusinessActivities = (activities) =>
  activities
    .map((activity) => ({
      id: activity.Id,
      value: activity.Description,
      ...activity,
    }))
    .sort((a, b) => (a.value < b.value ? -1 : 1));

export const normalizeOptions = (options) =>
  options
    .map((option) => ({
      id: option.Value,
      value: option.Description,
      ...option,
    }))
    .sort((a, b) => (a.value < b.value ? -1 : 1));

export const normalizeFatca = (options) =>
  options
    .map((option) => ({
      id: option.Id,
      value: option.Description,
      hasGiin: option.Giin,
    }))
    .sort((a, b) => (a.value < b.value ? -1 : 1));

export const normalizeMtm = (options) =>
  options
    .map((option) => ({
      id: option.Id,
      clientElectronicCheck: option.ClientElectronicCheck,
      clientIp: option.ClientIp,
      customerId: option.CustomerId,
      mtmActivated: option.MtmActivated,
    }))
    .sort((a, b) => (a.value < b.value ? -1 : 1));
